/*
Author       : Dreamguys
Template Name: SmartHR - Bootstrap Admin Template
Version      : 3.2
*/

var moment = require('moment');

$(document).ready(function() {
    initAddVacationRequestModal();
    initAddVacationSuggestionModal();
    initEmployeeModal();
    initDutyLeaveModal();
    initLeagalHolidayModal();
    initAddEmployeeModal();
    initVacationLockModal();
    initPrivacySettings();
});


function initAddVacationSuggestionModal() {


    $('#add_vacation_suggestion_form_start').prop( "disabled", true );
    $('#add_vacation_suggestion_form_end').prop( "disabled", true );


    $('#add_vacation_suggestion_form_employee').change(function (e) {
        $('#add_vacation_suggestion_form_start').prop( "disabled", false );
        $('#add_vacation_suggestion_form_end').prop( "disabled", false );



    })
    $('#add_vacation_suggestion_form_start').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: moment().endOf("year")
    });

    $('#add_vacation_suggestion_form_end').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: moment().endOf("year")
    });


    $('form[name="add_vacation_suggestion_form"]').validate({
        errorElement:'em', errorPlacement:function errorPlacement(error, element) {
            error.addClass('invalid-feedback');
            if(element.prop('type')==='checkbox') {
                error.insertAfter(element.parent('label'));
            }
            else {
                error.insertAfter(element);
            }
        },
        highlight:function highlight(element) {
            $(element).addClass('is-invalid').removeClass('is-valid');
        },
        unhighlight:function unhighlight(element) {
            $(element).addClass('is-valid').removeClass('is-invalid');
        },
        rules: {
            'add_vacation_suggestion_form[employee]': 'required',
            'add_vacation_suggestion_form[start]': 'required',
            'add_vacation_suggestion_form[end]': 'required',
            'add_vacation_suggestion_form[comment]': {
                required: false
            },
            'add_vacation_suggestion_duration': {
                required: true,
                min: 1
            },
            'add_vacation_suggestion_remaining_days': {
                required: true,
                min: 0
            }
        },
        messages: {
            add_vacation_suggestion_duration: 'Mindestens 1 Urlaubstag muss beantragt werden',
            add_vacation_suggestion_remaining_days: 'Nicht genug Urlaubstage verfügbar'
        },
        submitHandler: function(form) {
            $(":submit", this).attr("disabled", "disabled");
            form.submit()
        }

    });


    $('#add_vacation_suggestion_form_start').on('dp.change', function (e) {

        var end = $('#add_vacation_suggestion_form_end').data("DateTimePicker").viewDate().format('DD-MM-YYYY');
        var start = e.date.format('DD-MM-YYYY');



        $('#add_vacation_suggestion_form_end').data("DateTimePicker").minDate(start);

        if($('#add_vacation_suggestion_form_end').is(':enabled')) {
            addVacationSuggestionFormDateHandler(start, end);
        }
    })

    $('#add_vacation_suggestion_form_start').on('dp.hide', function (e) {
        $('#add_vacation_suggestion_form_end').prop( "disabled", false );
        $('#add_vacation_suggestion_form_comment').prop( "disabled", false );
        $('#add_vacation_suggestion_form_end').data("DateTimePicker").minDate(e.date);
    });



    $('#add_vacation_suggestion_form_end').on('dp.change', function (e) {
        var start = $('#add_vacation_suggestion_form_start').data("DateTimePicker").viewDate().format('DD-MM-YYYY');
        var end = e.date.format('DD-MM-YYYY');

        addVacationSuggestionFormDateHandler(start, end);

    });
}

function initAddVacationRequestModal() {
    var year = $('#add_vacation_request form').data('year');
    var maxYear = moment(year + '-12-31', 'YYYY-MM-DD');

    $('#add_vacation_request_form_start').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: maxYear
    });

    $('#add_vacation_request_form_end').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: maxYear
    });


    $('form[name="add_vacation_request_form"]').validate({
        errorElement:'em', errorPlacement:function errorPlacement(error, element) {
            error.addClass('invalid-feedback');
            if(element.prop('type')==='checkbox') {
                error.insertAfter(element.parent('label'));
            }
            else {
                error.insertAfter(element);
            }
        },
        highlight:function highlight(element) {
            $(element).addClass('is-invalid').removeClass('is-valid');
        },
        unhighlight:function unhighlight(element) {
            $(element).addClass('is-valid').removeClass('is-invalid');
        },
        rules: {
            'add_vacation_request_form[start]': 'required',
            'add_vacation_request_form[end]': 'required',
            'add_vacation_request_form[comment]': {
                required: false
            },
            'add_vacation_request_duration': {
                required: false,
                min: 1
            },
            'add_vacation_request_remaining_days': {
                required: false,
                min: 0
            }
        },
        messages: {
            add_vacation_request_duration: 'Mindestens 1 Urlaubstag muss beantragt werden',
            add_vacation_request_remaining_days: 'Nicht genug Urlaubstage verfügbar'
        },
        submitHandler: function(form) {
            $(":submit", this).attr("disabled", "disabled");
            form.submit()
        }

    });


    $('#add_vacation_request_form_start').on('dp.change', function (e) {

        $('#add_vacation_request_form_end').data("DateTimePicker").clear();
    })
    $('#add_vacation_request_form_start').on('dp.hide', function (e) {


        $('#add_vacation_request_form_end').prop( "disabled", false );
        $('#add_vacation_request_form_comment').prop( "disabled", false );

        $('#add_vacation_request_form_end').data("DateTimePicker").minDate(e.date);
        $('#add_vacation_request_form_end').data("DateTimePicker").date(e.date)
    });

}

function addVacationSuggestionFormDateHandler(start, end) {
    var employee = $('#add_vacation_suggestion_form_employee').val();
    $.getJSON('/api/vacation/getSuggestionInfo/' + start + '/' + end + '/' + employee, function(data) {
        console.log(data)
        //$('#add_vacation_request .info_wrapper').html('');
        $('#add_vacation_suggestion_duration').val(data.duration);
        $('#add_vacation_suggestion_remaining_days').val(data.remaining_vacation_days);

        var validator = $('form[name="add_vacation_suggestion_form"]').validate();

        validator.element( "#add_vacation_suggestion_remaining_days" );
        validator.element( "#add_vacation_suggestion_duration" );
        /*
        $.each(data.info, function (key, value) {
            $.each(value, function (key, text) {
                var element = $('<div class="alert alert-primary alert-dismissible fade show" role="alert"></div>');

                var button = $('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');


                element.append(text);
                element.append(button)
                $('#add_vacation_request .info_wrapper').append(element)
            });
        })
*/
    });
}

function addVacationRequestFormDateHandler(start, end) {

    $.getJSON('/api/vacation/getRequestInfo/' + start + '/' + end, function(data) {
        console.log(data)
        //$('#add_vacation_request .info_wrapper').html('');
        $('#add_vacation_request_duration').val(data.duration);
        $('#add_vacation_request_remaining_days').val(data.remaining_vacation_days);

        var validator = $('form[name="add_vacation_request_form"]').validate();

        validator.element( "#add_vacation_request_remaining_days" );
        validator.element( "#add_vacation_request_duration" );
        /*
        $.each(data.info, function (key, value) {
            $.each(value, function (key, text) {
                var element = $('<div class="alert alert-primary alert-dismissible fade show" role="alert"></div>');

                var button = $('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');


                element.append(text);
                element.append(button)
                $('#add_vacation_request .info_wrapper').append(element)
            });
        })
*/
    });
}

function initEmployeeModal() {
    $('#delete_employee, #deactivate_employee, #activate_employee').on('show.bs.modal', function(e) {
        console.log('hallo');
        var button = $(e.relatedTarget) // Button that triggered the modal
        var link = button.attr('href');
        var element = $(this).find('a.continue-btn')
        element.on('click', function (e) {
            e.preventDefault();
            window.location.href = link;
        })
    });

    $('#edit_employee').on('show.bs.modal', function(e) {
        var button = $(e.relatedTarget) // Button that triggered the modal
        var employee = button.data('employee');

        console.log('Hallo');
        $.getJSON('/api/v1/employee/load_form_data/' + employee, null ,function(data) {
            $.each(data, function (index, value) {
                console.log(index, value);
                $('#' + index).val(value);
            })

            $('#employee_update_form_update_employee_manager').trigger('change');
        });

    });
    $('#employee_update_form_update_contract_joining_date').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        }
    });

    $('#employee_update_form_update_employee_birthday').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        }
    });

}

function initDutyLeaveModal() {
    var minYear = $('#create_duty_leave').data('year');
    var start = moment(minYear + '-01-01', 'YYYY-MM-DD');
    var end = moment(minYear + '-12-31', 'YYYY-MM-DD');

    $('#create_duty_leave_form_date_from').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: start,
        maxDate: end
    });
    $('#create_duty_leave_form_date_till').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: start,
        maxDate: end
    });

    $('#create_duty_leave_form_date_from').on('dp.change', function (e) {
        var start = e.date.format('DD-MM-YYYY');
        $('#create_duty_leave_form_date_till').data("DateTimePicker").minDate(start)
    })
}

function initLeagalHolidayModal() {
    var minYear = $('#create_legal_holiday_form_date').data('year');
    var start = moment(minYear + '-01-01', 'YYYY-MM-DD');
    var end = moment(minYear + '-12-31', 'YYYY-MM-DD');

    $('#create_legal_holiday_form_date').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: start,
        maxDate: end
    });
}

function initAddEmployeeModal() {
    $('#employee_form_contract_joining_date').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: moment().endOf("year")
    });

    $('#employee_form_employee_birthday').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: moment().startOf("year"),
        maxDate: moment().endOf("year")
    });
}

function initDutyLeaveCancellationModal() {
    //
}


function initVacationLockModal() {
    var minYear = $('#create_vacation_lock').data('year');
    var start = moment(minYear + '-01-01', 'YYYY-MM-DD');
    var end = moment(minYear + '-12-31', 'YYYY-MM-DD');

    $('#create_vacation_lock_form_date_from').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: start,
        maxDate: end
    });

    $('#create_vacation_lock_form_date_till').datetimepicker({
        locale: 'de',
        format: 'DD/MM/YYYY',
        icons: {
            up: "fa fa-angle-up",
            down: "fa fa-angle-down",
            next: 'fa fa-angle-right',
            previous: 'fa fa-angle-left'
        },
        minDate: start,
        maxDate: end
    });

    $('#create_vacation_lock_form_date_from').on('dp.change', function (e) {
        var start = e.date.format('DD-MM-YYYY');
        $('#create_vacation_lock_form_date_till').data("DateTimePicker").minDate(start)
    })
}


function initPrivacySettings() {
    $('#settings_privacy_display_vacation').on('change', function () {
        window.location.href = '/api/v1/settings/privacy/display_vacations';
    })
}
