/*
Author       : Dreamguys
Template Name: SmartHR - Bootstrap Admin Template
Version      : 3.2
*/

var moment = require('moment');

$(document).ready(function() {

	// Variables declarations

	var $wrapper = $('.main-wrapper');
	var $pageWrapper = $('.page-wrapper');
	var $slimScrolls = $('.slimscroll');
	var $sidebarOverlay = $('.sidebar-overlay');

	// Sidebar

	var Sidemenu = function() {
		this.$menuItem = $('#sidebar-menu a');
	};

	function init() {
		var $this = Sidemenu;
		$('#sidebar-menu a').on('click', function(e) {
			if($(this).parent().hasClass('submenu')) {
				e.preventDefault();
			}
			if(!$(this).hasClass('subdrop')) {
				$('ul', $(this).parents('ul:first')).slideUp(350);
				$('a', $(this).parents('ul:first')).removeClass('subdrop');
				$(this).next('ul').slideDown(350);
				$(this).addClass('subdrop');
				$(".submenu .active").parent().parent().css('display','block');
			} else if($(this).hasClass('subdrop')) {
				$(this).removeClass('subdrop');
				$(this).next('ul').slideUp(350);
			}
		});
		$('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');
	}

	// Sidebar Initiate
	init();

	// Sidebar overlay

	function sidebar_overlay($target) {
		if($target.length) {
			$target.toggleClass('opened');
			$sidebarOverlay.toggleClass('opened');
			$('html').toggleClass('menu-opened');
			$sidebarOverlay.attr('data-reff', '#' + $target[0].id);
		}
	}

	// Mobile menu sidebar overlay

	$(document).on('click', '#mobile_btn', function() {
		var $target = $($(this).attr('href'));
		sidebar_overlay($target);
		$wrapper.toggleClass('slide-nav');
		$('#task_window').removeClass('opened');
		return false;
	});

	// Chat sidebar overlay

	$(document).on('click', '#task_chat', function() {
		var $target = $($(this).attr('href'));
		sidebar_overlay($target);
		return false;
	});

	// Sidebar overlay reset

	$sidebarOverlay.on('click', function() {
		var $target = $($(this).attr('data-reff'));
		if($target.length) {
			$target.removeClass('opened');
			$('html').removeClass('menu-opened');
			$(this).removeClass('opened');
			$wrapper.removeClass('slide-nav');
		}
		return false;
	});

	// Select 2

	if($('.select').length > 0) {
		$('.select').select2({
			minimumResultsForSearch: -1,
			width: '100%'
		});
	}

	// Modal Popup hide show

	if($('.modal').length > 0 ){
		var modalUniqueClass = ".modal";
		$('.modal').on('show.bs.modal', function(e) {
		  var $element = $(this);
		  var $uniques = $(modalUniqueClass + ':visible').not($(this));
		  if ($uniques.length) {
			$uniques.modal('hide');
			$uniques.one('hidden.bs.modal', function(e) {
			  $element.modal('show');
			});
			return false;
		  }
		});
	}

	// Floating Label

	if($('.floating').length > 0 ){
		$('.floating').on('focus blur', function (e) {
		$(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
		}).trigger('blur');
	}

	// Sidebar Slimscroll

	if($slimScrolls.length > 0) {
		$slimScrolls.slimScroll({
			height: 'auto',
			width: '100%',
			position: 'right',
			size: '7px',
			color: '#ccc',
			wheelStep: 10,
			touchScrollStep: 100
		});
		var wHeight = $(window).height() - 60;
		$slimScrolls.height(wHeight);
		$('.sidebar .slimScrollDiv').height(wHeight);
		$(window).resize(function() {
			var rHeight = $(window).height() - 60;
			$slimScrolls.height(rHeight);
			$('.sidebar .slimScrollDiv').height(rHeight);
		});
	}

	// Page Content Height

	var pHeight = $(window).height();
	$pageWrapper.css('min-height', pHeight);
	$(window).resize(function() {
		var prHeight = $(window).height();
		$pageWrapper.css('min-height', prHeight);
	});



	// Datatable

	if($('.datatable').length > 0) {
		$('.datatable').DataTable({
			"bFilter": false,
		});
	}

	// Tooltip

	if($('[data-toggle="tooltip"]').length > 0) {
		$('[data-toggle="tooltip"]').tooltip();
	}

	// Email Inbox

	if($('.clickable-row').length > 0 ){
		$(".clickable-row").click(function() {
			window.location = $(this).data("href");
		});
	}

	// Check all email

	$(document).on('click', '#check_all', function() {
		$('.checkmail').click();
		return false;
	});
	if($('.checkmail').length > 0) {
		$('.checkmail').each(function() {
			$(this).on('click', function() {
				if($(this).closest('tr').hasClass('checked')) {
					$(this).closest('tr').removeClass('checked');
				} else {
					$(this).closest('tr').addClass('checked');
				}
			});
		});
	}

	// Mail important

	$(document).on('click', '.mail-important', function() {
		$(this).find('i.fa').toggleClass('fa-star').toggleClass('fa-star-o');
	});

	// Summernote

	if($('.summernote').length > 0) {
		$('.summernote').summernote({
			height: 200,                 // set editor height
			minHeight: null,             // set minimum height of editor
			maxHeight: null,             // set maximum height of editor
			focus: false                 // set focus to editable area after initializing summernote
		});
	}

	// Task Complete

	$(document).on('click', '#task_complete', function() {
		$(this).toggleClass('task-completed');
		return false;
	});

	// Multiselect

	if($('#customleave_select').length > 0) {
		$('#customleave_select').multiselect();
	}
	if($('#edit_customleave_select').length > 0) {
		$('#edit_customleave_select').multiselect();
	}

	// Leave Settings button show

	$(document).on('click', '.leave-edit-btn', function() {
		$(this).removeClass('leave-edit-btn').addClass('btn btn-white leave-cancel-btn').text('Cancel');
		$(this).closest("div.leave-right").append('<button class="btn btn-primary leave-save-btn" type="submit">Save</button>');
		$(this).parent().parent().find("input").prop('disabled', false);
		return false;
	});
	$(document).on('click', '.leave-cancel-btn', function() {
		$(this).removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
		$(this).closest("div.leave-right").find(".leave-save-btn").remove();
		$(this).parent().parent().find("input").prop('disabled', true);
		return false;
	});

	$(document).on('change', '.leave-box .onoffswitch-checkbox', function() {
		var id = $(this).attr('id').split('_')[1];
		if ($(this).prop("checked") == true) {
			$("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
			$("#leave_"+id+" .leave-action .btn").prop('disabled', false);
		}
	    else {
			$("#leave_"+id+" .leave-action .btn").prop('disabled', true);
			$("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
			$("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
			$("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
			$("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
		}
	});

	$('.leave-box .onoffswitch-checkbox').each(function() {
		var id = $(this).attr('id').split('_')[1];
		if ($(this).prop("checked") == true) {
			$("#leave_"+id+" .leave-edit-btn").prop('disabled', false);
			$("#leave_"+id+" .leave-action .btn").prop('disabled', false);
		}
	    else {
			$("#leave_"+id+" .leave-action .btn").prop('disabled', true);
			$("#leave_"+id+" .leave-cancel-btn").parent().parent().find("input").prop('disabled', true);
			$("#leave_"+id+" .leave-cancel-btn").closest("div.leave-right").find(".leave-save-btn").remove();
			$("#leave_"+id+" .leave-cancel-btn").removeClass('btn btn-white leave-cancel-btn').addClass('leave-edit-btn').text('Edit');
			$("#leave_"+id+" .leave-edit-btn").prop('disabled', true);
		}
	});

	// Placeholder Hide

	if ($('.otp-input, .zipcode-input input, .noborder-input input').length > 0) {
		$('.otp-input, .zipcode-input input, .noborder-input input').focus(function () {
			$(this).data('placeholder', $(this).attr('placeholder'))
				   .attr('placeholder', '');
		}).blur(function () {
			$(this).attr('placeholder', $(this).data('placeholder'));
		});
	}

	// OTP Input

	if ($('.otp-input').length > 0) {
		$(".otp-input").keyup(function(e) {
			if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
				$(e.target).next('.otp-input').focus();
			} else if (e.which == 8) {
				$(e.target).prev('.otp-input').focus();
			}
		});
	}

	// Small Sidebar

	$(document).on('click', '#toggle_btn', function() {
		if($('body').hasClass('mini-sidebar')) {
			$('body').removeClass('mini-sidebar');
			$('.subdrop + ul').slideDown();
		} else {
			$('body').addClass('mini-sidebar');
			$('.subdrop + ul').slideUp();
		}
		return false;
	});
	$(document).on('mouseover', function(e) {
		e.stopPropagation();
		if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
			var targ = $(e.target).closest('.sidebar').length;
			if(targ) {
				$('body').addClass('expand-menu');
				$('.subdrop + ul').slideDown();
			} else {
				$('body').removeClass('expand-menu');
				$('.subdrop + ul').slideUp();
			}
			return false;
		}
	});

	$(document).on('click', '.top-nav-search .responsive-search', function() {
		$('.top-nav-search').toggleClass('active');
	});
/*




    $('#edit_employee').on('show.bs.modal', function(e) {
        var button = $(e.relatedTarget) // Button that triggered the modal
        var employee = button.data('employee');

    	console.log('Hallo');
        $.getJSON('/api/v1/employee/load_form_data/' + employee, null ,function(data) {
        	$.each(data, function (index, value) {
        		console.log(index, value);
        		$('#' + index).val(value);
            })

            $('#employee_update_form_update_employee_manager').trigger('change');
		});

    });

	$('#copy_contract').on('show.bs.modal', function(e) {
		var button = $(e.relatedTarget) // Button that triggered the modal
		var employee = button.data('employee');
		var year = button.data('year');
		var vacation_days = button.data('days');
		var special_days = button.data('special');

		$('#copy_contract_form_copy_contract_vacation_days').val(vacation_days);
		$('#copy_contract_form_copy_contract_specialdays').val(special_days);
		$('#copy_contract_form_employee_id').val(employee);
		$('#copy_contract_form_year_id').val(year);



	});

	$('#add_vacation_request').on('show.bs.modal', function(e) {
		var date = moment('2019-12-31', 'YYYY-MM-DD');
		$('#add_vacation_request_form_start').data("DateTimePicker").maxDate(date);

	});



    $('#add_vacation_request_form_start').on('dp.change', function (e) {
		var end = $('#add_vacation_request_form_end').data("DateTimePicker").viewDate().format('DD-MM-YYYY');
		var start = e.date.format('DD-MM-YYYY');
		var startMoment = moment(e.date.format('YYYY-MM-DD'));

		$('#add_vacation_request_form_end').data("DateTimePicker").maxDate(startMoment.endOf("year"));



		if($('#add_vacation_request_form_end').is(':enabled')) {
			addVacationRequestFormDateHandler(start, end);
		}


	})
	$('#add_vacation_request_form_start').on('dp.hide', function (e) {
		$('#add_vacation_request_form_end').prop( "disabled", false );
		$('#add_vacation_request_form_ nt').prop( "disabled", false );

		$('#add_vacation_request_form_end').data("DateTimePicker").minDate(e.date);
	});



	$('#add_vacation_request_form_end').on('dp.hide', function (e) {

		var start = $('#add_vacation_request_form_start').data("DateTimePicker").viewDate().format('DD-MM-YYYY');
		var end = e.date.format('DD-MM-YYYY');

	})

	$('#add_vacation_request_form_end').on('dp.change', function (e) {
		var start = $('#add_vacation_request_form_start').data("DateTimePicker").viewDate().format('DD-MM-YYYY');
		var end = e.date.format('DD-MM-YYYY');


		addVacationRequestFormDateHandler(start, end);

	});


	$('form[name="add_vacation_request_form"]').validate({
		errorElement:'em', errorPlacement:function errorPlacement(error, element) {
			error.addClass('invalid-feedback');
			if(element.prop('type')==='checkbox') {
				error.insertAfter(element.parent('label'));
			}
			else {
				error.insertAfter(element);
			}
		},
		highlight:function highlight(element) {
			$(element).addClass('is-invalid').removeClass('is-valid');
		},
		unhighlight:function unhighlight(element) {
			$(element).addClass('is-valid').removeClass('is-invalid');
		},
		rules: {
			'add_vacation_request_form[start]': 'required',
			'add_vacation_request_form[end]': 'required',
			'add_vacation_request_form[comment]': {
				required: false
			},
			'add_vacation_request_duration': {
				required: true,
				min: 1
			},
			'add_vacation_request_remaining_days': {
				required: true,
				min: 0
			}
		},
		messages: {
			add_vacation_request_duration: 'Mindestens 1 Urlaubstag muss beantragt werden',
			add_vacation_request_remaining_days: 'Nicht genug Urlaubstage verfügbar'
		},
		submitHandler: function(form) {
			$(":submit", this).attr("disabled", "disabled");
			form.submit()
		}

	});





	$('#settings_privacy_display_vacation').on('change', function () {
		window.location.href = '/api/v1/settings/privacy/display_vacations';
	})

*/


});




// Loader

$(window).on ('load', function (){
	$('#loader').delay(100).fadeOut('slow');
	$('#loader-wrapper').delay(500).fadeOut('slow');
});

function addVacationRequestFormDateHandler(start, end) {

	$.getJSON('/api/v1/vacation/getVacationRequestDuration/' + start + '/' + end, function(data) {
		$('#add_vacation_request .info_wrapper').html('');
		$('#add_vacation_request_duration').val(data.days);
		$('#add_vacation_request_remaining_days').val(data.days_left);

		var validator = $('form[name="add_vacation_request_form"]').validate();

		validator.element( "#add_vacation_request_remaining_days" );
		validator.element( "#add_vacation_request_duration" );
		$.each(data.info, function (key, value) {
			$.each(value, function (key, text) {
				var element = $('<div class="alert alert-primary alert-dismissible fade show" role="alert"></div>');

				var button = $('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>');


				element.append(text);
				element.append(button)
				$('#add_vacation_request .info_wrapper').append(element)
			});
		})

	});
}
