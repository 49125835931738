/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('./css/bootstrap.css');
require('./css/font-awesome.min.css');
require('./css/line-awesome.min.css');
require('./css/select2.min.css');
require('./css/bootstrap-datetimepicker.min.css');
require('./css/dataTables.bootstrap4.min.css');
require('./css/style.css');
require('./css/app.css');


const $ = require('jquery');

require('popper.js');
require('./js/bootstrap.js');
require('./js/jquery.slimscroll');
require('./js/select2.min');
require('moment');
require('./js/bootstrap-datetimepicker.min');
require('./js/dataTables.bootstrap4.min');
require('./js/app.js');
require('./js/app-v2.js');
require('jquery-validation');

